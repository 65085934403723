import React from 'react';
import EnterEmail from '../components/enter-email';

export default class About extends React.Component {
    render() {
        return (
            <div className="content-block about">
                <div className="titleMedium about">About us</div>
                <div className="about-container">
                    {/* <div className="about-image-container">
                        <img className="about-image" src={require("../assets/images/team.jpg")} />
                    </div> */}
                    <div className="about-text">
                        <p className="content">
                            Our small but mighty team of industry experts and developers was assembled equally for our smarts and our heart.  It's personal for us; we run <span className="color-primary">OLT</span> with heart from the inside out.
                        </p>
                        <p className="content">
                            Determined to build technology to do good, we've ensured <span className="color-primary">OLT's</span> platform is best-in-class and ready to grow when you do.
                        </p>
                        <p className="content">
                            We're committed to <span className="color-primary">OLT</span> empowering you to give back by helping you create jobs, provide cheaper power, and strengthen your community with a product that's uniquely yours. <span className="color-primary">OLT's</span> power could be in your hands.
                        </p>
                        <p className="content">
                            The <span className="color-primary">OLT</span> team all love being a part of something, especially a cause that we're emotionally invested in.  We can do it with power!
                        </p>
                        <p className="content">
                            Are you ready to join Team Good?
                        </p>
                    </div>
                    <EnterEmail />
                </div>
            </div>
        );
    }
}