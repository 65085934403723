import React from 'react';

export default class Privacy extends React.Component {
    render() {
        return (
            <div className="content-block privacy">
                <p className="titleMedium color-primary">OLT Privacy Policy</p>
                <p>Our privacy policy describes how we collect and manages the personal information (such as your email address
                and payment details) that you provide to us. In general, the information collected from you is held in accordance
                with the Privacy Act 1993.</p>
                <p>As outlined in our Terms and Conditions you agree that any information you provide to us is correct. We may not
                be able to supply the platform to you if your information is incorrect or you do not provide us with the information
                we require. It is important that you contact us and update your details or any information you have provided if
                they change.</p>
                <p>OLT will collect information from you when you use our website or email us with information we require. Typically,
                this is collected when you join OLT or email us.</p>
                <p>We collect this information to provide you with the products and services we offer, to ensure we are
                communicating with the correct customer, ensuring the correct customer pays for their own invoices, to inform
                you of any other products and services we believe may be of interest to you, to improve our products and services,
                to perform our responsibilities in accordance with statute, or industry rules and regulations and finally, to enforce
                the terms of our contract with customers or our arrangements with our other suppliers.</p>
                <p className="titleMedium color-primary">Changes to our Privacy Policy</p>
                <p>We reserve the right, at our discretion, to update this Privacy Policy at any time.</p>
                <p>Changes to the Privacy Policy will be published on our website and will be effective from the date of publication.</p>
                <p className="titleMedium color-primary">Information Security</p>
                <p>OLT has electronic and physical security measures in place to protect against the loss, access, misuse and,
                alteration and/or unauthorised disclosure of the information under our control.</p>
                <p>However, while we strive to do everything reasonably possible to protect your personal information and privacy,
                we cannot guarantee or warrant the security of any information you disclose or transmit to us and we are not
                responsible for the theft, destruction, or inadvertent disclosure of your personal information where our security
                measures have been breached. Where your information is stored or processed by authorised third parties, we will
                take all reasonable steps to ensure that it is securely handled.</p>
                <p>Our website may contain hyperlinks to other websites that are not managed or controlled by us. This Privacy Policy
                does not cover the use of such websites. You should review the privacy statements of those websites to
                understand how your information is collected, stored and used.</p>
                <p>You will be required to choose a password when you apply to join OLT and this password is used to give you access
                to your online account with us. You are responsible for keeping your OLT Privacy Policy password confidential and
                for all the activities that occur in your account. Please notify us immediately if you become aware of unauthorised
                use of your password.</p>
                <p className="titleMedium color-primary">Accessing Your Information</p>
                <p>You are entitled to access the personal information we hold about you, and request that we amend it if it is
                incorrect. You may request to view, correct or update the personal information we hold about you by emailing us
                at contact@getoltered.com.</p>
                <p className="titleMedium color-primary">Disclosing Information About You</p>
                <p>OLT will not disclose, sell, distribute or share your personal information except in accordance with the Privacy Act
                1993 or where you have provided authorisation for disclosure.</p>
                <p>As outlined above your personal information may be made available within OLT and to:</p>
                <p>• Authorised agents, suppliers and companies who provide related services of the OLT platform.</p>
                <p>• Web usage analytics agencies (including Google Analytics) to understand how our website is being used
                and provide you with a personalised experience.</p>
                <p>• Industry regulators and other organisations who have a right to that information to assist in the
                performance of their functions and meet our obligations under existing regulations (such as - the
                Guideline on Arrangements to Assist Vulnerable Consumer and the Guideline on Arrangements to Assist
                Medically Dependent Consumers).</p>
                <p className="titleMedium color-primary">Residents outside of New Zealand</p>
                <p>Our site can be accessed by people all over the world, in different countries with different laws.</p>
                <p>Other jurisdictions may have different laws regarding the storage and use of your personal information. By visiting
                our site and providing us with data, you acknowledge and agree that your personal information may be processed
                for the purposes identified in this policy.</p>
                <p className="titleMedium color-primary">GoCardless</p>
                <p>We use GoCardless to process your Direct Debit payments. More information on how GoCardless processes your
                personal data and your data protection rights, including your right to object, is available at <a style={{ textDecoration: 'underline' }} href="https://gocardless.com/legal/privacy/">gocardless.com/legal/privacy.</a></p>
                <br />
                <br />
                <p>Effective from 12th Decmber 2018.</p>
            </div>
        );
    }
}