import React, { Component } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { Header, Footer } from './components/HeaderFooter';
import Home from './pages/Home';
import BurgerMenu from './components/BurgerMenu';
import Privacy from './pages/privacy';

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openMenu: false,
        }
    }

    toggleMenu = () => {
        this.setState({ openMenu: !this.state.openMenu });
    }

    render() {
        return (
            <div className="appContainer">
                {this.state.openMenu && <BurgerMenu toggleMenu={this.toggleMenu} />}
                <Header toggleMenu={this.toggleMenu} />
                <Switch>
                    <Route path="/privacy" component={Privacy} />
                    <Route component={Home} />
                </Switch>
                <Footer />
            </div>
        );
    }
}
export default withRouter(App);
