import React, { Component } from 'react';
import EnterEmail from '../components/enter-email';
import HomeImages from '../components/home-images';
import About from './About';
import Partners from './partners';

export default class Home extends Component {
    render() {
        return (
            <div>
                <div className="main">
                    <div className="homeStart">
                        <img className={`homeStartLogoImage`} src={require('../assets/images/logo.svg')} />
                        <div className="textBox">
                            <div className="titleMedium"><span className="color-primary">OLT</span> is on a mission</div>
                            <div className="content">Our mission is to build the world's first global electricity platform, making life more affordable, and communities happier all over the world.</div>
                        </div>
                        <div className="homeStartEyes">
                            <img src={require('../assets/images/eyes.png')} />
                        </div>
                    </div>
                    <div id="enterEmail" className="content-block enterEmail">
                        <div className="enterEmail content">
                            <span className="color-primary">OLT</span> partners with businesses and founders to make it easy and cheap to establish and run a power company.
                        </div>
                        <div className="enterEmail content">The savings can then be passed on to customers, communities and investors.</div>
                        <div className="enterEmail titleMedium">It's super easy, and takes less than 5 days.</div>
                        <EnterEmail />
                    </div>
                    <HomeImages />
                    <About />
                    <Partners />
                </div>
            </div>
        );
    }
}