import React from 'react';
import validator from 'validator';
import autoBind from 'react-autobind';

export default class EnterEmail extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            submittedContact: false,
            contactEmail: "",
            errorContact: "",
            messageContact: "",
        }
        autoBind(this);
    }

    updateContactEmail(event) {
        event.preventDefault();
        this.setState({ contactEmail: event.target.value });
    }

    submitContactEmail(event) {
        event.preventDefault();
        //For using release variable
        //let apiUrl = undefinedIfTemplate('#{apiUrl}');
        if (this.state.contactEmail) {
            if (!validator.isEmail(this.state.contactEmail)) {
                this.setState({ errorContact: " Please enter valid email address." });
            }
            else {
                this.setState({ submittedContact: true, messageContact: "...", errorContact: "" });
                var data = { contactEmail: this.state.contactEmail };
                //for localhost,api is "http://localhost:51071/api/email/sendContactEmail"
                //for prod, api is https://olt-server.azurewebsites.net/api/email/sendContactEmail
                fetch("https://olt-server.azurewebsites.net/api/email/sendContactEmail", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json"
                    },
                    body: JSON.stringify(data)
                }).then((result) => {
                    this.setState({ messageContact: "Thanks for contacting us. We will get back to you soon.", disableButtons: true });
                });
            }
        } else {
            this.setState({ errorContact: "Please enter a valid email address." });
        }
    }



    render() {
        return (
            this.state.submittedContact
                ?
                <span className="emailSent"><br />{this.state.messageContact}<br /></span>
                :
                <div className="w-100">
                    <input type="text" value={this.state.contactEmail} onChange={this.updateContactEmail} name="contactEmail" default="" placeholder="Enter your email address" />
                    <button className="button" name="submit" onClick={this.submitContactEmail}>Submit</button>
                    <div className="emailError">{this.state.errorContact}</div>
                    <p style={{ fontSize: 20, marginTop: 10 }}>OLT Limited, L3 24 Anzac Parade, Hamilton, Waikato, New Zealand 3216</p>
                </div>
        );
    }
}