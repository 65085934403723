import React from 'react';

export default class Partners extends React.Component {
    render() {
        return (
            <div className="content-block partners">
                <div className="titleMedium">Our Partners</div>
                <div className="partners-container">
                    <img src={require('../assets/images/partner-logos/azure-logo.png')} className="partner" />
                    <img src={require('../assets/images/partner-logos/microsoft-logo.png')} className="partner" />
                    <img src={require('../assets/images/partner-logos/react-logo.png')} className="partner" />
                    <img src={require('../assets/images/partner-logos/contentful-logo.png')} className="partner" />
                    <img src={require('../assets/images/partner-logos/google-maps-logo.png')} className="partner" />
                    <img src={require('../assets/images/partner-logos/gocardless-logo.png')} className="partner" />
                    <img src={require('../assets/images/partner-logos/mailgun-logo.png')} className="partner" />
                </div>
            </div>
        );
    }
}