import React from 'react';
import undefinedIfTemplate from '../helper/undefinedIfTemplate';
const lightforceVideo = undefinedIfTemplate("#{retailer-video-lightforce}") || "https://lightforcepower.nz/5349a45de0ae0625a1eac5be702e74a8.png";
const nmrVideo = undefinedIfTemplate("#{retailer-video-naumaira}") || "https://www.youtube.com/embed/p90cRhxFvXo";
const comtricityVideo = undefinedIfTemplate("#{retailer-video-comtricity}") || "https://www.youtube.com/embed/5yf1zZFHrvU";
const herEnergyVideo = undefinedIfTemplate("#{retailer-video-herenergy}") || "https://www.youtube.com/embed/KSKRy6CU1Mw";
const dynamicEnergyVideo = undefinedIfTemplate("#{retailer-video-dynamic}") || "https://www.youtube.com/embed/5yf1zZFHrvU";

export default class HomeImages extends React.Component {

    stringIsImagePath = (string) => {
        if (string.endsWith('jpg') || string.endsWith('png') || string.endsWith('svg'))
            return true;
        return false;
    }

    render() {
        return (
            <div id="retailers" className="retailer-videos">
                <div className="retailer-videos-title">#getOLTered</div>
                <div className="retailer-videos-container">
                    <div className="retailer-video">
                        <a href="https://www.facebook.com/naumairanz/" target="_blank" className="retailer-video-logo">
                            <img src={require('../assets/images/retailer-logos/nmr-logo.png')} />
                            <h1>Nau Mai Ra</h1>
                        </a>
                        <div className="retailer-video-iframe-container">
                            {this.stringIsImagePath(nmrVideo) ?
                                <div className="retailer-video-placeholder-image" style={{ backgroundImage: `url(${nmrVideo})` }} />
                                :
                                <iframe src={nmrVideo} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            }
                        </div>
                    </div>
                    <div className="retailer-video">
                        <a href="https://app.comtricity.nz/" target="_blank" className="retailer-video-logo">
                            <img src={require('../assets/images/retailer-logos/comtricity-logo.png')} />
                            <h1>Comtricity</h1>
                        </a>
                        <div className="retailer-video-iframe-container">
                            {this.stringIsImagePath(comtricityVideo) ?
                                <div className="retailer-video-placeholder-image" style={{ backgroundImage: `url(${comtricityVideo})` }} />
                                :
                                <iframe src={comtricityVideo} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            }
                        </div>
                    </div>
                    <div className="retailer-video">
                        <a href="https://lightforcepower.nz/" target="_blank" className="retailer-video-logo">
                            <img src={require('../assets/images/retailer-logos/lightforce-logo.svg')} />
                            <h1>Lightforce Power</h1>
                        </a>
                        <div className="retailer-video-iframe-container">
                            {this.stringIsImagePath(lightforceVideo) ?
                                <div className="retailer-video-placeholder-image" style={{ backgroundImage: `url(${lightforceVideo})` }} />
                                :
                                <iframe src={lightforceVideo} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}