import React, { Component } from 'react';
import { HashLink } from 'react-router-hash-link'

export default class BurgerMenu extends Component {

    render() {
        return (
            <div className="burgerMenu">
                <div className="header flex">
                    <div className="close" onClick={() => this.props.toggleMenu()}>×</div>
                </div>
                <div className="burger-menu-logo" />
                <div className="burger-menu-items">
                    <HashLink className={`${window.location.hash == "#retailers" ? "active" : ""}`} to="/#retailers" onClick={() => this.props.toggleMenu()}>
                        Case studies
                    </HashLink>
                    <HashLink className={`${window.location.hash == "#enterEmail" ? "active" : ""}`} to="/#enterEmail" onClick={() => this.props.toggleMenu()}>
                        Contact
                    </HashLink>
                </div>
            </div>
        );
    }
}