import React, { Component } from 'react';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import Terms from '../assets/pdfs/olt terms.pdf';
import Privacy from '../assets/pdfs/olt privacy.pdf';

export class Header extends Component {
    render() {
        return (
            <div className="headerContainer">
                <div className="header flex">
                    <Link to="/"><img className={`headerLogoImage`} src={require('../assets/images/logo.svg')} /></Link>
                    <div className="headerNavButtonContainer">
                        <HashLink className={`headerHomeButton${window.location.hash == "#retailers" ? "active" : ""}`} to={'/#retailers'}>Case studies</HashLink>
                        <HashLink className={`headerHomeButton${window.location.hash == "#enterEmail" ? "active" : ""}`} to={'/#enterEmail'}>Contact</HashLink>
                    </div>
                    <div className="headerNavButtonContainer">
                        {/* <img className={`headerLogoImage`} src={require('../assets/images/social-sites.png')} /> */}
                    </div>
                    <div className="menuToggle" onClick={() => this.props.toggleMenu()}>≡</div>
                </div>
            </div>
        );
    }
}

export class Footer extends Component {
    render() {
        return (
            <div className="footer">
                <div className="footerInfo">
                    <HashLink className={`${window.location.hash == "#enterEmail" ? "active" : ""}`} to={'/#enterEmail'}>Contact us</HashLink>
                    <a href={Terms}>Terms and Conditions</a>
                    <Link to="/privacy">Privacy Policy</Link>
                    <div>©2020 OLT. All rights reserved</div>
                </div>
            </div>
        );
    }
}
